<template>
  <div class="cloud-exhibitor-list">
    <AddressPosition />
    <div class="list-container">
      <!-- <div class="header">
        <span class="text">展商介绍</span>
      </div> -->
      <!-- 列表 -->
      <el-row v-if="loading || list && list.length" class="list" :gutter="45">
        <el-col v-for="item in loading ? 12 : list" :key="loading ? item : item.id" :span="6">
          <el-skeleton :loading="loading" animated style="width: 373px;">
              <ShadowCard cover-width="200px" clickable @click.native="onClickExhibitor(item)">
                <template #content>
                  <div class="card-title">{{ item.companyName }}</div>
                </template>
              </ShadowCard>
              <template #template>
                <el-skeleton-item variant="p" />
              </template>
            </el-skeleton>
        </el-col>
      </el-row>
      <el-empty v-else-if="!loading" :image-size="200" />
      <!-- 分页 -->
      <el-pagination
        class="cloud-pagination"
        style="text-align: center;margin-bottom: 60px;"
        background
        layout="prev, pager, next, total, jumper"
        prev-text="上一页"
        next-text="下一页"
        :page.sync="listQuery.pageNum"
        :page-size.sync="listQuery.pageSize"
        :total="total"
        @current-change="getListData"
      />
    </div>
  </div>
</template>

<script>
import ShadowCard from '@/components/ShadowCard.vue'
import AddressPosition from '@/components/AddressPosition'
import { getExhibitorList } from '@/api'
import { MC_H5_BASE_URL } from '@/utils/constant'

export default {
  name: 'CloudExhibitorList',
  
  components: { AddressPosition, ShadowCard },

  data() {
    return {
      // 列表
      list: [],
      total: 0,
      loading: true,
      // 列表请求参数
      listQuery: {
        pageNum: 1,
        pageSize: 16
      }
    }
  },

  created() {
    this.getListData()
  },

  methods: {
    /* 获取展商列表数据 */
    getListData(pageNum) {
      this.loading = true
      // 构造请求参数
      const { listQuery } = this
      const query = {
        ...listQuery,
        pageNum: pageNum || listQuery.pageNum
      }
      return getExhibitorList(query).then(res => {
        const { content: list, total } = res.data
        this.total = total
        this.list = list
      }).catch(error => {
        this.list = []
        this.total = 0
        console.log('getListData', error)
      }).finally(() => {
        this.loading = false
      })
    },

    /* 展商的点击事件处理 */
    onClickExhibitor(row) {
      const { id = 0, companyNumber = '', companyName = '' } = row
			const isMcCompany = !!companyNumber
      if (isMcCompany) {
				// 苗仓企业, 跳转到苗仓H5企业详情页
				window.open(`${MC_H5_BASE_URL}/company/${companyNumber}`)
			} else {
				// 非苗仓企业, 打开当前项目的展商介绍详情页
        window.open(`/#/cloud/exhibitor/detail?id=${id}`)
			}
    }
  }
}
</script>

<style lang="less" scoped>
.cloud-exhibitor-list {
  width: 1280px;
  margin: 0 auto;
  .list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .header {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(332px - 32px * 2);
      height: 70px;
      margin: 70px 0;
      font-size: 28px;
      font-weight: 600;
      color: #fff;
      // background: linear-gradient(90deg, #c9d7ff 0%, #7f95d1 100%);
      background: linear-gradient(90deg, #9aee96 0%, #349477 100%);
      .icon {
        margin-right: 5px;
        object-fit: cover;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -31px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 35px 32px 35px 0;
        // border-color: transparent #c9d7ff transparent transparent;
        border-color: transparent #9aee96 transparent transparent;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -31px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 35px 0 35px 32px;
        // border-color: transparent transparent transparent #7f95d1;
        border-color: transparent transparent transparent #349477;
      }
    }
    .list {
      margin: 40px 0;
      min-height: 288px;
      min-width: 1280px;
      .el-row {
        width: 1280px;
      }
      .el-col {
        margin-bottom: 20px;
      }
      .card-title {
        width: 200px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        color: #333;
        transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
        &:hover {
          color: #fff;
          box-shadow: inset 200px 0 0 0 #177D6A;
        }
      }
    }
  }
}
</style>